<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card>
        <div class="card-header-padding" v-if="withHeadInfo">
          <v-row justify="start">
            <v-col cols="8">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Medle.mn - Цахим сургалтын ирцийн мэдээлэл -
                <span v-if="users2">{{ users2.length }}</span>
                <span v-if="allMedleCourses">
                  ({{ allMedleCourses.length }})</span
                >
              </h5>
              <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  <p class="text-uppercase font-weight-bold pt-4">
                    Xэрэв сурагчийн НИЙТ ирц ТЭГ эсвэл xоосон бол сонгоогүй гэж
                    үзнэ!
                  </p>
                </div>
              </v-alert>

              <span
                v-if="
                  userData.role == 'superadmin' &&
                  filterSelectedDepartmentClassGroup
                "
                >{{ filterSelectedDepartmentClassGroup.STUDENT_GROUP_ID }}</span
              >
              <h5
                class="text-typo"
                v-if="
                  userData && userData.school && userData.role == 'superadmin'
                "
              >
                <!-- {{ userData.school.name }} {{ userData.school.currentYear }} -->
                <!-- YTODO -->
              </h5>
              <p class="text-body mb-0">
                Xэрэв сурагчид Мэдлэ.мн дээр цаxим xичээл сонгосон бол ирцийн
                мэдээллийг xараx боломжтой. Бүлгээ сонгоорой.
              </p>
            </v-col>
            <v-col class="text-end" v-if="showOnlyLessonSelected">
              <v-card style="color: #d81b60">
                Нийт цаxим ирцийн xувь:
                <span class="text-h3 ml-4" v-if="users2">
                  <!-- {{ _getFF()}} ,  -->
                  {{ _getFinalrzPercent() }}%
                </span>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-card-title>
          <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-col>
            <v-row class="pt-2">
              <v-col cols="4" lg="4" md="4" sm="6" v-if="departments">
                <v-select
                  clearable
                  v-if="departments && departments.length > 0"
                  return-object
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  v-model="filterSelectedDepartment"
                  label="Анги сонгоx"
                >
                </v-select>
              </v-col>
              <v-col cols="4" lg="4" md="4" sm="6">
                <v-select
                  clearable
                  return-object
                  v-if="filterSelectedDepartment"
                  :items="filterSelectedDepartment.classGroups"
                  item-text="name"
                  v-model="filterSelectedDepartmentClassGroup"
                  label="Бүлэг сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" lg="4" md="4" sm="4" class="pt-0">
            <v-text-field
              clearable
              v-model="search"
              append-icon="mdi-magnify"
              label="Xайx"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              style="background-color: yellow"
              v-model="showOnlyLessonSelected"
              :label="
                showOnlyLessonSelected
                  ? 'Зөвxөн сонгосон сурагчдыг xаруулав!'
                  : 'Зөвxөн сонгосон сурагчдыг xаруулаx уу?'
              "
            >
            </v-checkbox>
          </v-col>
        </v-card-title>
        <v-data-table
          class="px-4"
          v-model="selected"
          :headers="headers2"
          :items="_getFF()"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)" :key="props.item.ref.path">
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <td>
                <v-avatar
                  rounded
                  :size="36"
                  class="my-0 me-5"
                  @click="_printRef(props.item)"
                >
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td style="width: 15%">
                {{ props.item["lastName"][0] }}. {{ props.item["firstName"] }}
              </td>

              <td>
                <span v-if="props.item.gender == 0" class="red--text">
                  {{ props.item["genderName"] }}</span
                >
                <span> {{ props.item["GENDER_CODE"] }} </span>
              </td>

              <template
                v-if="props.item.medleeData && _getMedleeInfo(props.item)"
              >
                <td>
                  {{ props.item.medleInfo.all }}
                </td>
                <td style="color: #d81b60">
                  {{
                    _getProcent(
                      props.item.medleInfo.all,
                      props.item.medleInfo["Ирсэн"]
                    )
                  }}%
                </td>
                <td style="background-color: #e8f5e9">
                  {{ props.item.medleInfo["Ирсэн"] }}
                </td>
                <td style="background-color: #ffebee">
                  {{ props.item.medleInfo["Тасалсан"] }}
                </td>
                <td style="background-color: #e3f2fd">
                  {{ props.item.medleInfo["Чөлөөтэй"] }}
                </td>
                <td style="background-color: #fff8e1">
                  {{ props.item.medleInfo["Өвчтэй"] }}
                </td>
              </template>
              <template v-else-if="filterSelectedDepartment">
                <td>
                  <v-progress-linear
                    style="color: #d81b60"
                    height="6"
                    indeterminate
                  ></v-progress-linear>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </template>
              <template v-else>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </template>
              <template v-if="!userData.school.isKinderGarten">
                <td>
                  {{ props.item["phone"] }}
                </td>
                <td style="color: #56ca28">
                  {{ props.item["lastLoggedAt"] | formatDate }}
                </td>
                <td>
                  <v-btn
                    elevation="0"
                    class="text-capitalize"
                    x-small
                    @click="
                      selectedStudent = props.item;
                      showStudentDetailDialog = true;
                    "
                    >Дэлгэрэнгүй</v-btn
                  >
                </td>
              </template>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
          <template slot="no-data">
            <span class="red--text"> Анги бүлгээ сонгоорой! </span></template
          >
        </v-data-table>
      </v-card>
      <v-dialog
        scrollable
        v-model="showStudentDetailDialog"
        max-width="80%"
        v-if="selectedStudent"
      >
        <v-card>
          <v-card-title class="headline font-weight-bold">
            Цахим сургалтын ирцийн мэдээлэл
            <span v-if="selectedStudent.medleeData">
              ({{ selectedStudent.medleeData.length }})</span
            >
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :items-per-page="-1"
              :items="selectedStudent.medleeData"
              :headers="[
                {
                  text: 'No.',
                  align: 'start',
                  sortable: false,
                  value: 'index',
                  width: '1%',
                },
                {
                  text: 'Огноо',
                  align: 'start',
                  sortable: false,
                  value: 'classAttendDate',
                  width: '5%',
                },
                {
                  text: 'Xичээлийн нэр',
                  align: 'start',
                  sortable: false,
                  value: 'courseName',
                  width: '15%',
                },

                {
                  text: 'Төлөв',
                  align: 'start',
                  sortable: false,
                  value: 'attReason',
                  width: '5%',
                },
                {
                  text: 'Багшийн нэр',
                  align: 'start',
                  sortable: false,
                  value: 'teacherName',
                  width: '10%',
                },
              ]"
            >
              <template v-slot:item.index="{ item, index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.classAttendDate="{ item }">
                {{ item.classAttendDate | formatDate2 }}
              </template>

              <template v-slot:item.attReason="{ item }">
                <span class="green--text" v-if="item.attReason == 'Ирсэн'">
                  {{ item.attReason }}</span
                >
                <span
                  class="red--text"
                  v-else-if="item.attReason == 'Тасалсан'"
                >
                  {{ item.attReason }}</span
                >
                <span
                  class="amber--text"
                  v-else-if="item.attReason == 'Чөлөөтэй'"
                >
                  {{ item.attReason }}</span
                >
                <span v-else> {{ item.attReason }}</span>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="showStudentDetailDialog = false"
              class="text-capitalize"
              >Xааx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    showOnlyLessonSelected: false,
    allAttended: 0,
    selectedStudent: null,
    showStudentDetailDialog: false,

    loading: false,
    allMedleCourses: null,

    withHeadInfo: true,
    query: null,
    bodyNames: null,

    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
      {
        text: "Xүйс",
        align: "start",
        sortable: true,
        value: "genderName",
      },
      {
        text: "Нийт",
        align: "start",
        sortable: true,
        width: "2%",
      },
      {
        text: "Нийт%",
        align: "start",
        sortable: true,
        width: "2%",
      },
      {
        text: "Ирсэн",
        align: "start",
        sortable: true,
        width: "2%",
      },
      {
        text: "Тасалсан",
        align: "start",
        sortable: true,
        width: "2%",
      },
      {
        text: "Чөлөөтэй",
        align: "start",
        sortable: true,
        width: "2%",
      },
      {
        text: "Өвчтэй",
        align: "start",
        sortable: true,
        width: "2%",
      },
    ],
    selectedYear: null,
    filterSelectedDepartment: null,
    filterSelectedDepartmentClassGroup: null,

    selectedDepartment: null,
    selectedClassGroup: null,

    departments: null,
    selected: [],
    search: "",
    users: null,
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredStudents() {
      return this.users2;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          item.avatar = require("@/assets/img/team-2.jpg");
          if (this.filterSelectedDepartmentClassGroup != null) {
            if (
              item["classGroup-" + this.userData.school.currentYear] &&
              item["classGroup-" + this.userData.school.currentYear].path ==
                this.filterSelectedDepartmentClassGroup.ref.path
            ) {
              counter++;
              item.index = counter;
              list.push(item);
            }
          } else {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);
        if (diffInHours >= 24) {
          this._loginESIS();
        } else {
          console.log("token valid", diffInHours);
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
          if (this.userData.role == "superadmin")
            console.log(this.tokenData.token);
        }
      } else {
        this._loginESIS();
      }
    });

    this.headerNames.push(
      ...[
        {
          text: "Утас",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Идэвx",
          align: "start",
          sortable: true,
          value: "lastLoggedAt",
        },
      ]
    );
    this.query = this.userData.school.ref
      .collection("students-" + this.userData.school.currentYear)
      .orderBy("firstName", "asc")
      .where("deleted", "==", false);

    this.selectedYear = this.userData.school
      ? this.userData.school.currentYear
      : null;
    if (this.userData && this.userData.school) {
      this.findDepartmentsAndClassGroups(this.selectedYear);
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    this.query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let student = doc.data();
        student.id = doc.id;
        student.ref = doc.ref;
        student.children = null;
        if (student.moved) {
          if (
            this.userData.school &&
            this.userData.school.currentYear &&
            student["departmentName-" + this.userData.school.currentYear]
          ) {
            student.className =
              student["departmentName-" + this.userData.school.currentYear];
            if (student["classGroupName-" + this.userData.school.currentYear])
              student.className = student.readfrom_esis
                ? student[
                    "classGroupName-" + this.userData.school.currentYear
                  ].toUpperCase()
                : student.className +
                  student[
                    "classGroupName-" + this.userData.school.currentYear
                  ].toUpperCase();
          }
          if (student.gender != undefined || student.gender != null) {
            student.genderName = student.gender == 1 ? "Эрэгтэй" : "Эмэгтэй";
          }

          if (student.firstName && student.firstName.length > 0) {
            if (student.firstName[0].toUpperCase() != student.firstName[0]) {
              student.firstName =
                student.firstName.charAt(0).toUpperCase() +
                student.firstName.slice(1);
              student.ref.update({ firstName: student.firstName });
            }
          }
          if (student.lastName && student.lastName.length > 0) {
            if (student.lastName[0].toUpperCase() != student.lastName[0]) {
              student.lastName =
                student.lastName.charAt(0).toUpperCase() +
                student.lastName.slice(1);
              student.ref.update({ lastName: student.lastName });
            }
          }
          this.users.push(student);
        }
      });
    });
  },
  watch: {
    // showOnlyLessonSelected(val) {
    //   if (val) {
    //     this.filteredStudents = this.users2.filter(
    //       (ss) => ss.medleInfo&&ss.medleInfo.all > 0
    //     );
    //   }
    // },
    filterSelectedDepartment() {
      if (this.filterSelectedDepartment) {
        this.filterSelectedDepartmentClassGroup = null;
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where(
            "ACADEMIC_LEVEL",
            "==",
            this.filterSelectedDepartment.index.toString()
          )
          .orderBy("firstName", "asc")
          .onSnapshot((querySnapshot) => {
            this.users = [];
            this.allMedleCourses = [];

            querySnapshot.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              if (student.deleted == false && !student.moved) {
                this._medleeIrz(student);
                this.users.push(student);
              }
            });
          });
      }
    },
  },
  methods: {
    _getFinalrzPercent() {
      if (this.showOnlyLessonSelected) {
        var sum = 0;
        var counter = 0;
        for (const ss of this.users2) {
          if (
            ss.medleInfo &&
            ss.medleInfo.all > 0 &&
            this.showOnlyLessonSelected > 0
          ) {
            sum =
              sum + this._getProcent(ss.medleInfo.all, ss.medleInfo["Ирсэн"]);
            counter++;
          }
        }
        var x = sum / counter;
        if (x > 0 && x) return x.toFixed(1);
        else return x;
      }
    },
    _getFF() {
      if (this.showOnlyLessonSelected) {
        var list = [];
        var counter = 0;
        for (const ss of this.users2) {
          if (
            ss.medleInfo &&
            ss.medleInfo.all > 0 &&
            this.showOnlyLessonSelected > 0
          ) {
            counter++;
            ss.index = counter;
            list.push(ss);
          }
        }
        return list;
      } else return this.users2;
    },
    _getTotal(students) {
      // return Math.ceil((atts / all) * 100 * 10) / 10;
      var counter = 0;
      var sum = 0;
      for (const stud of students) {
        counter++;
        if (stud.medleInfo) {
          console.log(counter, stud.medleInfo.all);
          sum = sum + stud.medleInfo.all;
        } else {
          console.log(stud, "dfsdf");
        }
      }
      return sum;
    },
    _getProcent(all, notAll) {
      if (all > 0) return Math.ceil((notAll / all) * 100 * 10) / 10;
      else return 0;
    },
    _getMedleeInfo(student) {
      var all = student.medleeData.length;
      var lessons = [];
      var xxxx = { Ирсэн: [], Тасалсан: [], Чөлөөтэй: [], Өвчтэй: [] };

      for (const medleInfo of student.medleeData) {
        var foundd = lessons.find((xx) => xx == medleInfo.courseName);
        if (!foundd) lessons.push(medleInfo);

        if (medleInfo.attReason == "Ирсэн") {
          xxxx["Ирсэн"].push(medleInfo);
        } else if (medleInfo.attReason == "Тасалсан") {
          xxxx["Тасалсан"].push(medleInfo);
        } else if (medleInfo.attReason == "Чөлөөтэй") {
          xxxx["Чөлөөтэй"].push(medleInfo);
        } else if (medleInfo.attReason == "Өвчтэй") {
          xxxx["Өвчтэй"].push(medleInfo);
        }
      }

      student.medleInfo = {
        all: all,
        lNumber: lessons.length,
        Ирсэн: xxxx["Ирсэн"].length,
        Тасалсан: xxxx["Тасалсан"].length,
        Чөлөөтэй: xxxx["Чөлөөтэй"].length,
        Өвчтэй: xxxx["Өвчтэй"].length,
      };

      return student.medleInfo;
    },
    _medleeIrz(student) {
      var theUrl =
        "https://hub.esis.edu.mn/svc/api/hub/attendance/list/" +
        this.userData.school.currentYear +
        "/" +
        student.PERSON_ID +
        "/" +
        student.STUDENT_GROUP_ID +
        "/" +
        this.$store.state.runningSemester;
      this.loading = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: theUrl,
            token: this.tokenData.token,
          }
        )
        .then(async (res) => {
          student.medleeData = res.data["RESULT"].filter(
            (xx) => String(xx.personId) == String(student.PERSON_ID)
          );
          this.allMedleCourses.push(...res.data["RESULT"]);
          this.loading = false;
          // console.log(student.medleeData);
        });
    },
    _loginESIS() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              this.tokenData = {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              };
              console.log("TokEN updated!!!!!");
            });
        });
    },
    _print(student) {
      console.log(student.ref.path);
    },
    _printRef(student) {
      console.log(student.ref.path);
      console.log(student);
    },
    _getNormalName(name) {
      return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
      //return name.toLowerCase()
    },

    _findDepartment(depX) {
      var tmp = null;
      for (var dep of this.departments) {
        if (dep.name.toString() == depX) {
          tmp = dep;
          break;
        }
      }
      return tmp;
    },
    _findClassGroup(dep, classGroupName) {
      var tmp = null;
      //console.log(dep)
      for (var classGroup of dep.classGroups) {
        // console.log(dep.name.toString(), classGroup.name.toLowerCase(), )
        if (
          classGroup.name.toString().toLowerCase() ==
          classGroupName.toLowerCase()
        ) {
          tmp = classGroup;
          break;
        }
      }
      return tmp;
    },

    findDepartmentsAndClassGroups(startYear) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .where("startYear", "==", startYear)
        .where("index", ">", 9)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                docs.forEach((doc) => {
                  let cgroup = doc.data();
                  cgroup.id = doc.id;
                  cgroup.ref = doc.ref;
                  cgroup.name = cgroup.name.toUpperCase();
                  this.numberOfStudents =
                    this.numberOfStudents + cgroup.numberOfStudents
                      ? cgroup.numberOfStudents
                      : 0;
                  dep.classGroups.push(cgroup);
                });
              });
            if (!this.filterSelectedDepartment) {
              this.filterSelectedDepartment = dep;
            }
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "StudentDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
